import { useRef, useState } from 'react';

import { $userSelection } from '@bees-force-shared/services';
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';

import { CustomLanguageType } from '@/components/Overview/types/FilterModalCalendar';
import { DateFormats } from '@/consts/GlobalConsts';
import { Locales } from '@/enums/locales';
import { useQueryVisitDetails } from '@/reactQueryHooks/visits/useQueryVisitDetails';
import { VisitEditSegmentProps } from '@/types/AnalyticsTypes';
import { GetVisitDetailsRequestQuery } from '@/types/domains/GetVisitDetailsDomain';
import { UpdateVisitRequestBodyTime } from '@/types/domains/UpdateVisitDomain';
import {
  formatDateToLocaleDate,
  formatStringDateToNewDate,
} from '@/utils/dateFormatter/dateFormatter';

import { useSegmentVisitEdit } from '../useSegmenVisitEdit/useSegmenVisitEdit';
import { useTranslation } from '../useTranslation/useTranslation';

const initialValues: UpdateVisitRequestBodyTime = {
  estimatedTimeMax: undefined,
  estimatedTimeMin: undefined,
  suggestedTime: undefined,
};

export const useEditVisitModal = (visitId: string) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [prevSelectedDate, setPrevSelectedDate] = useState<Date>(selectedDate);
  const language = usePreferredLanguageV2().preferredLanguage ?? Locales.enUS;
  const toastService = useToast();
  const { noDataAvailable, savedChanges, errorOnSave, errorOnSaveMinTime } = useTranslation();

  const [formEditVisit, setFormEditVisit] = useState<UpdateVisitRequestBodyTime>(initialValues);

  const state = $userSelection.getState();
  const vendorId = state?.operation?.vendorId;

  const handleChangeField = (field: string, value: number) => {
    setFormEditVisit((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const {
    actions: { patchVisit },
  } = useQueryVisitDetails({ id: visitId as string, vendorId } as GetVisitDetailsRequestQuery);

  const { dispatchVisitEditEvent } = useSegmentVisitEdit();

  const visitDataAnalytics = {
    [VisitEditSegmentProps.VisitID]: visitId,
    [VisitEditSegmentProps.VisitStatus]: '',
    [VisitEditSegmentProps.OldDate]: '',
    [VisitEditSegmentProps.NewDate]: '',
  };

  const handleOnDispatchVisitEditEvent = () => {
    visitDataAnalytics[VisitEditSegmentProps.VisitStatus] = selectedStatus;
    visitDataAnalytics[VisitEditSegmentProps.OldDate] = formatDateToLocaleDate(
      prevSelectedDate,
      Locales.enUS,
      DateFormats.SHORT_REVERSE,
    );
    visitDataAnalytics[VisitEditSegmentProps.NewDate] = formatDateToLocaleDate(
      selectedDate,
      Locales.enUS,
      DateFormats.SHORT_REVERSE,
    );
    dispatchVisitEditEvent(visitDataAnalytics);
  };

  const parseDateToString = () => {
    const currentDate = selectedDate;

    const formattedDate = formatDateToLocaleDate(
      new Date(currentDate),
      Locales.enUS,
      DateFormats.SHORT_REVERSE,
    );
    return formattedDate;
  };

  const parseNextVisitLabelDate = (date?: string) => {
    if (!date) return noDataAvailable;
    const parsedDate = formatDateToLocaleDate(
      formatStringDateToNewDate(date),
      language as Locales,
      DateFormats.MEDIUM,
    );
    return parsedDate;
  };

  const handleOnSaveDate = (date: Date) => {
    setSelectedDate((prev) => {
      setPrevSelectedDate(prev);
      return date;
    });
    setOpenCalendar(false);
  };

  const handleOnSaveModal = async () => {
    setOpenCalendar(false);

    //TODO: Update with zod implementation
    if (formEditVisit.estimatedTimeMin && formEditVisit.estimatedTimeMin < 2) {
      toastService.notify({
        message: errorOnSaveMinTime,
        type: TypeToast.ERROR,
      });

      return;
    }

    if (
      formEditVisit?.estimatedTimeMax &&
      formEditVisit?.estimatedTimeMin &&
      formEditVisit.estimatedTimeMax <= formEditVisit.estimatedTimeMin
    ) {
      toastService.notify({
        message: errorOnSave,
        type: TypeToast.ERROR,
      });

      return;
    }

    toastService.notify({
      message: savedChanges,
      type: TypeToast.SUCCESS,
    });

    handleOnDispatchVisitEditEvent();

    await patchVisit.mutate({
      id: visitId,
      data: { ...formEditVisit, visitDate: parseDateToString() },
    });

    setFormEditVisit(initialValues);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  const calendarLanguage = (): CustomLanguageType | undefined => {
    const lang = language && language.split('-')[0];
    return lang as unknown as CustomLanguageType;
  };

  return {
    isOpenCalendar,
    setOpenCalendar,
    selectedDate,
    prevSelectedDate,
    setSelectedDate,
    setPrevSelectedDate,
    parseDateToString,
    parseVisitLabelDate: parseNextVisitLabelDate,
    handleOnSaveDate,
    handleOnSaveModal,
    containerRef,
    selectedStatus,
    setSelectedStatus,
    setFormEditVisit,
    calendarLanguage,
    formEditVisit,
    handleChangeField,
  };
};
