import MessageMap from '../i18n.d';

export const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visits',
      },
      Status: {
        Error: {
          ERROR_MESSAGE: 'Unable to load data',
          SUBTITLE: 'Try reloading the page.',
          ON_REFRESH: 'Reload',
        },
        LOADING: 'Loading data',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Overview',
        CANCELLED_VISITS: '% Canceled visits',
        BDRS_WITHOUT_VISITS: '% BDRs without visits',
        TOTAL_OF_VISITS: 'Total of visits',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' of [total] Visits',
        BDRS_WITHOUT_VISITS_TOTAL: 'of [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Last update [lastUpdate] ago.',
        FILTER_DESCRIPTION: 'Select a date range to be display data:',
      },
      Button: {
        REFRESH: 'Refresh',
        TRY_AGAIN: 'Try again',
      },
      Error: {
        REFRESH_ERROR: 'Unable to refresh data',
        DEFAULT_ERROR: 'Something went wrong.',
        RELOAD_BUTTON: 'Reload',
      },
      Filter: {
        Periods: {
          TODAY: 'Today',
          YESTERDAY: 'Yesterday',
          TOMORROW: 'Tomorrow',
          OTHER: 'Other',
        },
      },
      Graph: {
        TITLE: 'By status',
        SCHEDULED: 'Planned',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Finished',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
      SearchField: {
        PLACEHOLDER: 'Search',
      },
      LOADING: 'Loading content',
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'POC ID',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        SEARCH_BY: 'Search by',
      },
      Buttons: {
        SEARCH_BUTTON: 'Search',
        FILTERS_BUTTON: 'Filter',
        APPLY_BUTTON: 'Apply',
        CANCEL_BUTTON: 'Cancel',
        CLEAR_ALL_BUTTON: 'Clear all',
        RELOAD_BUTTON: 'Reload',
      },
      Title: {
        CARD_VISITS: 'Visits',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'Account ID [id] does not exist.',
        BDR_ID_ERROR: 'BDR [id] does not exist.',
        BDR_NAME_ERROR: 'BDR [name] does not exist.',
      },
      StatusVisits: {
        STATUS: 'Status',
        SCHEDULED: 'Planned',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Finished',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Showing [results] visits',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        POC_ID: 'POC ID',
        VISIT_DATE: 'Visit Date',
        MIN_TIME: 'Min. Time',
        MAX_TIME: 'Max. Time',
        MINUTES: 'minutes',
        QUANTITY_INDICATION: 'de',
        LOADING_CONTENT: 'Loading data',
        ERROR_CONTENT: 'Something went wrong.',
        NO_CONTENT: 'There is no content to be displayed.',
        SEARCH_NOT_FOUND: 'No results. Review your search and try again.',
        CLEAR_SEARCH: 'Clear search',
      },
      Filter: {
        FILTERED_BY: 'Filtered by',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs without visits',
      },
      Label: {
        SHOWING_RESULTS: 'Showing [results] results',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        DATE_WITHOUT_VISITS: 'Date without visit',
        SEARCH_BY: 'Search by BDR ID',
        PAGINATION_INDICATION: 'of',
      },
    },
    ModalChangeDDC: {
      Title: {
        CHANGE_DDC: 'Change DDC',
      },
      Paragraph: {
        FIND_ANOTHER_DDC: 'Find another DDC to manage by DDC ID or DDC Name.',
      },
      Button: {
        CANCEL: 'Cancel',
        CHANGE: 'Change',
        Label: {
          DDC: 'DDC',
        },
      },
      Select: {
        Search: {
          PLACEHOLDER: 'Search',
        },
        LABEL: 'DDC',
        NOT_FOUND: 'We did not find what you are looking for.',
        SHOW_ALL: 'Show all',
      },
    },
    ModalChangeOperation: {
      Button: {
        Label: {
          OPERATION: 'Operation',
          TOOLTIP_INFORMATION: 'The operation includes the organization and seller.',
        },
      },
      TITLE: 'Edit operation',
      SUB_TITLE: 'The operation will be applied to the visits page.',
      Actions: {
        CANCEL_BUTTON: 'Cancel',
        SAVE_BUTTON: 'Save',
      },
      Fields: {
        Country: {
          LABEL: 'Country',
          PLACEHOLDER: 'Select a Country',
        },
        Organization: {
          LABEL: 'Organization',
          HINT: 'May include companies.',
          PLACEHOLDER: 'Select an organization',
          ALERT: 'This country has no organizations',
        },
        Seller: {
          LABEL: 'Seller',
          HINT: 'Who will receive the visit.',
          PLACEHOLDER: 'Select a seller',
        },
        VendorId: {
          LABEL: 'Vendor ID',
          PLACEHOLDER: 'Select a vendor ID',
        },
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Visit Details',
        POC_INFORMATION: 'POC Information',
        NEXT_VISIT: 'Next Visit',
        BDR_INFORMATION: 'BDR Information',
        VISIT_INFORMATION: 'Visit Information',
        CANCEL_VISIT: 'Cancel Visit?',
      },
      Label: {
        VISIT_DATE: 'Visit Date',
        VISIT_STATUS: 'Visit Status',
        SUGGESTED_TIME: 'Suggested Time',
        MINIMUM_TIME: 'Minimum Time',
        MAXIMUM_TIME: 'Maximum Time',
        NO_NEXT_VISIT: 'There is no visit scheduled for this POC.',

        POC_NAME: 'POC Name',
        POC_ID: 'POC ID',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
        NO_POC_INFORMATION: 'There is no POC information for this visit.',

        BDR_NAME: 'BDR Name',
        BDR_ID: 'BDR ID',
        NO_BDR_INFORMATION: 'There is no BDR information for this POC.',

        SHOW_VISIT_DETAILS: 'Show visit details',
        MINUTES: '[min] minutes',
        NO_DATA_AVAILABLE: 'No data available',

        CANCEL_VISIT_DESCRIPTION:
          'This action can be undone as long as the date remains in the future.',

        LOADING_CONTENT: 'Loading data',
        ERROR_CONTENT: 'Something went wrong.',

        CANCELED_BY_USER_ADMIN: 'This visit was canceled by a BEES One user.',
        CANCELED_BY_USER_SYNC: 'This visit was canceled by a user in Sync.',
        CANCELED_BY_SYSTEM: 'This visit was canceled by the system due to [error].',
        CanceledBySystem: {
          INVALID_LAT_LONG: 'invalid latitude or longitude',
          NOT_IN_CUSTOMER: 'account unavailable to Vendor',
          NOT_IN_ACCOUNTS_BFF: 'account unavailable to Force',
          INVALID_NAME: 'invalid name',
          NOT_IN_UCC: 'invalid data in UCC',
          INVALID_DELIVERY_ADDRESS: 'invalid delivery address',
          BEES_ACCOUNT_ID_NULL: 'account ID null',
        },
      },
      Button: {
        CANCEL_MODAL: 'Cancel',
        GO_BACK: 'No, go back',
        CANCEL_VISIT: 'Yes, cancel',
        REFRESH: 'Reload',
      },
      Toast: {
        SUCCESS: 'Visit sucessfully canceled.',
      },
      PageStatus: {
        Error: {
          ERROR_MESSAGE: 'Unable to load data',
          SUBTITLE: 'Try reloading the page.',
          ON_REFRESH: 'Reload',
          ON_GO_TO: 'Go back',
        },
        LOADING: 'Loading data',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Save',
        RESET: 'Reset',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Planned',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Finished',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Edit visit',
        VISIT_DATE: 'Visit date',
        VISIT_STATUS: 'Visit status',
        NEXT_VISIT: 'Next visit',
        SCHEDULED: 'Planned',
        CANCELED: 'Canceled',
        NO_DATA_AVAILABLE: 'No data available',
        SAVED_CHANGE: 'Saved changes',
      },
      Button: {
        CANCEL: 'Cancel',
        SAVE_CHANGES: 'Save Changes',
        EDIT: 'Edit',
        SAVE: 'Save',
        RESET: 'Reset',
      },
      Input: {
        Label: {
          SUGGESTED_TIME: 'Suggested time',
          MINIMUM_TIME: 'Minimum time',
          MAXIMUM_TIME: 'Maximum time',
          PLACEHOLDER: 'Enter the time',
        },
      },
      Errors: {
        MAX_TIME_LESS_THAN_MIN_TIME:
          'The maximum time cannot be less than or equal to the minimum time.',
        MIN_TIME_LESS_THAN_2: 'The minimum time cannot be less than 2 minutes.',
      },
    },
  },
};
