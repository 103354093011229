import { AxiosResponse } from 'axios';

import { api } from '@/api/api';
import { ENDPOINT_GET_DDC } from '@/consts/Endpoints';

export const getDistributionCenters = async ({
  vendorId,
  page,
  signal,
  name,
}): Promise<AxiosResponse> =>
  await api.get({
    url: `${ENDPOINT_GET_DDC}`,
    config: {
      signal,
      params: {
        vendorId: vendorId,
        ...(name && { name: name }),
        page: page,
        pageSize: 10,
      },
    },
  });
