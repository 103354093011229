import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@bees-force-shared/services';

import { getVisits } from '@/api/services/visits/visits';
import { QUERY_KEY } from '@/consts/Query';
import { setVisits } from '@/stores/visits/Visits';
import { VisitsListRequestQuery } from '@/types/domains/GetVisitsDomain';

export function useQueryVisitList(visitsListRequestQuery: VisitsListRequestQuery) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorFromRefresh, setErrorFromRefresh] = useState(false);

  const { vendorId, startDate, endDate } = visitsListRequestQuery;
  const {
    data: visitsResponse,
    isError: isErrorVisits,
    isLoading: isLoadingVisits,
    isFetching: isFetchingVisits,
    refetch: refetchVisits,
  } = useQuery(
    [QUERY_KEY.VISITS, visitsListRequestQuery],
    ({ signal }) => {
      const { status, ...restParams } = visitsListRequestQuery;
      return getVisits({
        ...restParams,
        status: status == null ? undefined : status,
        signal,
      });
    },
    {
      enabled: !!vendorId && !!startDate && !!endDate,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (visitsResponseData) => {
        setVisits(visitsResponseData.data);
        setErrorFromRefresh(false);
      },
      onError: () => {
        if (isRefreshing) {
          setErrorFromRefresh(true);
        }
      },
      onSettled: () => {
        setIsRefreshing(false);
      },
    },
  );

  const refreshVisitsData = useCallback(() => {
    setIsRefreshing(true);
    refetchVisits();
  }, [refetchVisits]);

  useEffect(() => {
    return () => {
      setIsRefreshing(false);
    };
  }, []);

  return {
    visitsResponse,
    isErrorVisits,
    isLoadingVisits,
    isFetchingVisits,
    errorFromRefresh,
    refetchVisits,
    refreshVisitsData,
  };
}
