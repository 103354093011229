import { useUnit } from 'effector-react';

import { ErrorImage } from '@/components/Atoms/ErrorImage/ErrorImage';
import { vendorIds } from '@/mocks/vendorId';
import { useQueryVisitList } from '@/reactQueryHooks/visits/useQueryVisitList';
import { FiltersKpisParametersStore } from '@/stores/filtersKpisStore/FiltersKpisParametersStore';

import { TABLE_VISITS_TEST_IDS } from '../../consts/TableVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import * as Styled from './ErrorContent.styles';

export const ErrorContent = (): JSX.Element => {
  const { startDate, endDate } = useUnit(FiltersKpisParametersStore);

  /* TODO: Get the vendorId from localstorage after the operations US is done */
  const portalConfig = localStorage.getItem('portal-config');
  /* istanbul ignore next */
  const env = portalConfig ? JSON.parse(portalConfig).ENV : null;
  const { reloadButton, errorContent } = useTranslation();
  const { refetchVisits } = useQueryVisitList({ vendorId: vendorIds[env], startDate, endDate });

  return (
    <Styled.ErrorContentContainer data-testid={TABLE_VISITS_TEST_IDS.ERROR_CONTENT}>
      <ErrorImage />

      <Styled.ErrorContentLabel>{errorContent}</Styled.ErrorContentLabel>
      <Styled.ErrorContentButton
        elevated
        size="medium"
        variant="secondary"
        onClick={() => refetchVisits()}>
        <Styled.ErrorButtonIcon />
        {reloadButton}
      </Styled.ErrorContentButton>
    </Styled.ErrorContentContainer>
  );
};
