import { useEffect } from 'react';

import { VisitsActions } from '@/components/VisitsActions/VisitsActions';
import { vendorIds } from '@/mocks/vendorId';
import { setVendorId } from '@/stores/visitsParams/VisitsParamsEvents';

import { VisitsContent } from './components/VisitsContent/VisitsContent';
import { useVisits } from './hooks/useVisits';
import * as Styled from './Visits.styles';

export const Visits = (): JSX.Element => {
  const portalConfig = localStorage.getItem('portal-config');
  const env = portalConfig ? JSON.parse(portalConfig).ENV : null;
  useVisits();
  setVendorId(vendorIds[env]); //TODO: mocked vendorId should be changed when operations are ready

  useEffect(() => {
    document.body.style.setProperty('overflow-y', 'auto', 'important');
    return () => {
      document.body.style.removeProperty('overflow-y');
    };
  }, []);

  return (
    <Styled.VisitsPage>
      <VisitsActions />
      <VisitsContent />
    </Styled.VisitsPage>
  );
};
