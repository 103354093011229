import { Matcher } from 'react-day-picker';

import { CalendarRef } from '@hexa-ui/components';

import { CustomLanguageType } from '@/components/Overview/types/FilterModalCalendar';

interface Poc {
  id?: string;
  name?: string;
  email?: string;
  latitude?: string;
  longitude?: string;
}

interface BDR {
  id?: string;
  name?: string;
}

interface NextVisit {
  date?: string;
  status?: string;
}

export interface VisitType {
  id: string;
  date: string;
  status: string;
  suggestedTime: string;
  minimumTime: string;
  maximumTime: string;
  poc?: Poc;
  bdr?: BDR;
  nextVisit?: NextVisit;
  canceledDueTo?: string;
}

export interface VisitInformationProps {
  date: string;
  status: string;
  suggestedTime: string;
  minimumTime: string;
  maximumTime: string;
  isLoading: boolean;
  isError: boolean;
  onRefresh: () => void;
}

export interface NextVisitInformationProps {
  nextVisitDate: string;
  nextVisitStatus: string;
  nextVisitId?: string;
  isLoading: boolean;
  isError: boolean;
  onRefresh: () => void;
}

export interface BdrInformationProps {
  bdrId?: string;
  bdrName?: string;
  isLoading: boolean;
  isError: boolean;
  onRefresh: () => void;
}

export interface EmptyCardProps {
  emptyMessage: string;
}

export interface PocInformationProps {
  pocName?: string;
  pocId?: string;
  pocLatitude?: string;
  pocLongitude?: string;
  loading?: boolean;
  hasError?: boolean;
  onRefresh: () => void;
}

export interface CancelVisitModalProps {
  visitId: string;
}

export enum StatusVisit {
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  JUSTIFIED = 'justified',
  IN_PROGRESS = 'inProgress',
}

export interface SingleDatePickerProps {
  datePickerRef: React.RefObject<HTMLDivElement>;
  language: CustomLanguageType;
  onReset?: () => void;
  onSave?: (date: Date) => void;
}

export interface UseSingleDatePickerProps {
  disableActions: boolean;
  selectedDate: Date | undefined;
  calendarRef: React.RefObject<CalendarRef>;
  handleOnDateChange: (selection: Matcher | Matcher[] | undefined) => void;
  handleOnReset: () => void;
}
