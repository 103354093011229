import { HTMLInputTypeAttribute, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { InputWithLabel } from '@bees-force-shared/business-components';
import { useFeatureToggleEnabledByVendorId } from '@bees-force-shared/services';
import { Button, Heading, Modal, Select } from '@hexa-ui/components';
import { Calendar, ChevronDown, ChevronUp } from '@hexa-ui/icons';

import { RenderCondition } from '@/components/NoVisual/RenderCondition/RenderCondition';
import { FEATURE_TOGGLE_VISITS_PARAMETERS_EFFECTIVE_ROUTE } from '@/consts/FeatureToggles';

import { VISIT_EDIT_IDS } from '../../consts/EditVisitModalConsts';
import { StatusVisit } from '../../types/VisitDetails.types';
import { SingleDatePicker } from './components/SingleDatePicker/SingleDatePicker';
import { timeInputFields } from './consts/InputsTimeFields';
import * as Styled from './EditVisitModal.styles';
import { useEditVisitModal } from './hooks/useEditVisitModal/useEditVisitModal';
import { useTranslation } from './hooks/useTranslation/useTranslation';

interface EditVisitModalProps {
  status: string;
  date: Date;
  nextVisitDate?: string;
  visitId: string;
}

export const EditVisitModal = ({ status, date, nextVisitDate, visitId }: EditVisitModalProps) => {
  const { formatMessage } = useIntl();

  const {
    editVisit,
    visitDate,
    visitStatus,
    nextVisit,
    edit,
    canceled,
    scheduled,
    cancel,
    saveChanges,
  } = useTranslation();
  const {
    containerRef,
    handleOnSaveDate,
    handleOnSaveModal,
    isOpenCalendar,
    parseDateToString,
    parseVisitLabelDate,
    setOpenCalendar,
    selectedStatus,
    setSelectedStatus,
    setSelectedDate,
    calendarLanguage,
    formEditVisit,
    handleChangeField,
  } = useEditVisitModal(visitId);

  const { isEnabled: showInputTime } = useFeatureToggleEnabledByVendorId(
    FEATURE_TOGGLE_VISITS_PARAMETERS_EFFECTIVE_ROUTE,
  );

  const timeInputs = timeInputFields.map(({ id, stateKey, testId }) => ({
    required: true,
    label: formatMessage({ id: `Components.EditModal.Input.Label.${id}` }),
    type: 'number' as HTMLInputTypeAttribute,
    placeholder: formatMessage({ id: 'Components.EditModal.Input.Label.PLACEHOLDER' }),
    value: formEditVisit[stateKey],
    testId,
    onChange: (e) => handleChangeField(stateKey, Number(e.target.value)),
  }));

  useEffect(() => {
    setSelectedStatus(status);
    setSelectedDate(date);
  }, [status, setSelectedStatus, setSelectedDate, date]);

  useEffect(() => {
    const handleClickOutsideEvent = (event: MouseEvent) => {
      const getButtonLabelAttribute = event.target as HTMLElement;
      const eventId = getButtonLabelAttribute.getAttribute('id');
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        eventId !== VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON
      ) {
        setOpenCalendar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('pointerdown', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.addEventListener('pointerdown', handleClickOutsideEvent);
    };
  }, [containerRef, setOpenCalendar]);

  const modalActions = () => (
    <Styled.ModalActions>
      <Modal.Cancel>
        <Button
          data-testid={VISIT_EDIT_IDS.CANCEL_MODAL_EDIT_BUTTON}
          size="medium"
          variant="secondary"
          onClick={() => setOpenCalendar(false)}>
          {cancel}
        </Button>
      </Modal.Cancel>
      <Modal.Action>
        <Button
          data-testid={VISIT_EDIT_IDS.SAVE_MODAL_EDIT_BUTTON}
          size="medium"
          variant="primary"
          onClick={handleOnSaveModal}
          disabled={!selectedStatus}>
          {saveChanges}
        </Button>
      </Modal.Action>
    </Styled.ModalActions>
  );

  return (
    <Modal.Root
      actions={modalActions()}
      title={<Heading size="H3">{editVisit}</Heading>}
      trigger={
        <Button
          size="medium"
          variant="secondary"
          id={VISIT_EDIT_IDS.OPEN_MODAL_EDIT_BUTTON}
          data-testid={VISIT_EDIT_IDS.OPEN_MODAL_EDIT_BUTTON}>
          {edit}
        </Button>
      }>
      <Styled.WrapperModalContentFields>
        <Styled.WrapperDateAndStatus>
          <Styled.WrapperLabelWithCalendar>
            <span>{visitDate}</span>
            <Styled.OpenCalendarButton
              id={VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON}
              data-testid={VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON}
              size="large"
              variant="secondary"
              onClick={() => setOpenCalendar(!isOpenCalendar)}>
              <Styled.WrapperDateAndCalendar>
                <Styled.CustomIconButton variant="inherit" size="medium" icon={Calendar} />
                <span data-testid={VISIT_EDIT_IDS.SELECT_DATE_MODAL_EDIT}>
                  {parseDateToString()}
                </span>
              </Styled.WrapperDateAndCalendar>
              <Styled.CustomIconButton
                variant="inherit"
                size="small"
                icon={isOpenCalendar ? ChevronUp : ChevronDown}
              />
            </Styled.OpenCalendarButton>
          </Styled.WrapperLabelWithCalendar>

          {isOpenCalendar && (
            <SingleDatePicker
              datePickerRef={containerRef}
              onSave={handleOnSaveDate}
              language={calendarLanguage()}
            />
          )}
          <Styled.WrapperSelectStatus>
            <Select.Root
              data-testid={VISIT_EDIT_IDS.SELECT_STATUS_MODAL_EDIT}
              defaultValue="scheduled"
              label={visitStatus}
              value={selectedStatus}
              disabled={status === StatusVisit.SCHEDULED}
              onChange={setSelectedStatus}>
              <Select.Option value="canceled">{canceled}</Select.Option>
              <Select.Option value="scheduled">{scheduled}</Select.Option>
            </Select.Root>
          </Styled.WrapperSelectStatus>
        </Styled.WrapperDateAndStatus>

        <RenderCondition condition={showInputTime}>
          <Styled.WrapperInputTime>
            {timeInputs.map((inputProps) => (
              <InputWithLabel key={inputProps.testId} {...inputProps} />
            ))}
          </Styled.WrapperInputTime>
        </RenderCondition>
        <Styled.VisitLabel>
          <span>{nextVisit}</span>
          <span data-testid={VISIT_EDIT_IDS.NEXT_VISIT_MODAL_EDIT} className="visit-date">
            {parseVisitLabelDate(nextVisitDate)}
          </span>
        </Styled.VisitLabel>
      </Styled.WrapperModalContentFields>
    </Modal.Root>
  );
};
