import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useUnit } from 'effector-react';

import { useDistributionCenters } from '@/components/ModalChangeDDC/components/SelectWithSearch/hooks/useDistributionCenters/useDistributionCenters';
import { setSelectedDdc } from '@/stores/userInfoStore/UserInfoEvents';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';

export const useDdc = () => {
  const { ddc } = useUnit(UserInfoStore);
  const { ddcList } = useDistributionCenters();
  const { formatMessage } = useIntl();

  const handleDdcChange = useCallback(() => {
    if (!ddc.value && ddcList.length > 0) {
      setSelectedDdc({ ddc: { ...ddcList[0], value: ddcList[0].id } });
    }
  }, [ddc.value, ddcList]);

  useEffect(() => {
    handleDdcChange();
  }, [handleDdcChange]);

  const ddcNameFormatted = ddc.name
    ? ddc.name
    : formatMessage({ id: 'Components.ModalChangeDDC.Select.SHOW_ALL' });

  return { ddcNameFormatted };
};
