import { useCallback, useEffect, useState } from 'react';

import {
  $userSelection,
  setUserSelection,
  useOperations,
  checkLocalStorage,
} from '@bees-force-shared/services';

import { OperationData } from '../types/ModalChangeOperationTypes';
import { useSegmentOperationSelected } from './useSegmentOperationSelected/useSegmentOperationSelected';

export const useModalChangeOperation = () => {
  const { operations } = useOperations();

  const [isEditOperationModalOpen, setIsEditOperationModalOpen] = useState(false);
  const [currentOperation, setCurrentOperation] = useState('Country / Company / Seller');
  const [operationData, setOperationData] = useState<OperationData>({
    country: '',
    organization: { id: '', name: '' },
    seller: { id: '', name: '' },
    vendorId: '',
  });

  const { dispatchOperationSelectedEvent } = useSegmentOperationSelected();
  const position = 0;
  const group = operations.data?.content?.[position];
  const parentGrouIdEqualsToRoot = group?.parentGroupId === 'root';

  const getChildGroup = useCallback((group) => group?.childGroups?.[position] || null, [position]);

  const getVendorId = useCallback((group) => group?.vendors?.[position]?.id || null, [position]);

  const handleDefaultSeller = useCallback(() => {
    if (!group) return undefined;

    if (!parentGrouIdEqualsToRoot) {
      const childGroup = getChildGroup(group);
      return childGroup ? { id: childGroup.id, name: childGroup.name } : undefined;
    }

    const nestedChildGroup = getChildGroup(getChildGroup(group));
    return nestedChildGroup ? { id: nestedChildGroup.id, name: nestedChildGroup.name } : undefined;
  }, [group, parentGrouIdEqualsToRoot, getChildGroup]);

  const handleDefaultVendor = useCallback(() => {
    if (!group) return undefined;

    if (!parentGrouIdEqualsToRoot) {
      const childGroup = getChildGroup(group);
      return childGroup ? getVendorId(childGroup) : getVendorId(group);
    }

    const nestedChildGroup = getChildGroup(getChildGroup(group));
    return nestedChildGroup ? getVendorId(nestedChildGroup) : getVendorId(group);
  }, [group, parentGrouIdEqualsToRoot, getChildGroup, getVendorId]);

  const handleDefineOperationLabel = useCallback(() => {
    const countryLabel = operationData.country ? `${operationData.country}` : '';
    const organizationLabel = operationData.organization?.name
      ? `/ ${operationData.organization?.name}`
      : '';
    const sellerLabel = operationData.seller?.name ? `/ ${operationData.seller?.name}` : '';

    return `${countryLabel} ${organizationLabel} ${sellerLabel}`;
  }, [operationData.country, operationData.organization, operationData.seller]);

  useEffect(() => {
    const operationLabel = handleDefineOperationLabel();

    setCurrentOperation(operationLabel);
  }, [handleDefineOperationLabel]);

  useEffect(() => {
    if (!operationData.organization.id && !checkLocalStorage()?.operation.organization.id) {
      const defaultCountry = operations?.data?.countries?.[position]?.country || '';
      const child = getChildGroup(group);
      const defaultOrganization = parentGrouIdEqualsToRoot
        ? { id: child.id, name: child.name }
        : { id: group?.id, name: group?.name };

      const userSelection = {
        operation: {
          country: defaultCountry,
          organization: defaultOrganization,
          seller: handleDefaultSeller() ?? { id: '', name: '' },
          vendorId: handleDefaultVendor(),
        },
      };

      setOperationData(userSelection.operation);

      setUserSelection(userSelection);
    }
  }, [
    getChildGroup,
    group,
    handleDefaultSeller,
    handleDefaultVendor,
    operationData.organization.id,
    operations?.data?.content,
    operations?.data?.countries,
    parentGrouIdEqualsToRoot,
  ]);

  useEffect(() => {
    $userSelection.watch((userSelection) => {
      if (userSelection) {
        const { operation } = userSelection;
        const operationData = {
          country: operation.country,
          organization: { id: operation.organization?.id, name: operation.organization?.name },
          seller: { id: operation.seller?.id, name: operation.seller?.name },
          vendorId: operation.vendorId,
        };

        setOperationData(operationData);
      }
    });
  }, []);

  const handleCancel = () => {
    setIsEditOperationModalOpen(false);
  };

  const handleConfirm = (operationData: OperationData) => {
    let operation = 'Country / Company / Seller';

    if (operationData.seller && operationData.organization.id && operationData.country) {
      operation = `${operationData.country} / ${operationData.organization.name} / ${operationData.seller.name}`;
    } else if (operationData.organization.name && operationData.country) {
      operation = `${operationData.country} / ${operationData.organization.name}`;
    }
    dispatchOperationSelectedEvent(operationData);
    setCurrentOperation(operation);
    setOperationData(operationData);
  };

  const handleOpenModal = () => setIsEditOperationModalOpen(true);

  return {
    isEditOperationModalOpen,
    currentOperation,
    operationData,
    handleConfirm,
    handleOpenModal,
    handleCancel,
  };
};
