import { createEvent } from 'effector';

import { Order, VisitStatus } from '@/api/services/visits/types';

import { VisitsState } from './VisitsParamsState';

export const updateVisitsState = createEvent<Partial<VisitsState>>();
export const resetVisitsState = createEvent<void>();

export const setVendorId = (id: string) => updateVisitsState({ vendorId: id });
export const setVisitId = (id: string) => updateVisitsState({ visitId: id });
export const setDeliveryCenterId = (id: string | null) =>
  updateVisitsState({ deliveryCenterId: id });
export const setBdrId = (id?: string) => updateVisitsState({ bdrId: id });
export const setAccountId = (id?: string) => updateVisitsState({ accountId: id });
export const setStatus = (status: VisitStatus | null) => updateVisitsState({ status });
export const setPage = (page: number | null) => updateVisitsState({ page });
export const setPageSize = (size: number | null) => updateVisitsState({ pageSize: size });
export const setVisitDate = (date: string | null) => updateVisitsState({ visitDate: date });
export const setStartDate = (date: string | null) => updateVisitsState({ startDate: date });
export const setEndDate = (date: string | null) => updateVisitsState({ endDate: date });
export const setSort = (sort: Order | null) => updateVisitsState({ sort });
