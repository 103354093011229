import MessageMap from '../i18n.d';

export const idID: MessageMap = {
  test: {
    translate: 'Terjemahkan',
    missing: 'Hanya tersedia opsi bahasa Inggris',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Kunjungan',
      },
      Status: {
        Error: {
          ERROR_MESSAGE: 'Tidak dapat memuat data',
          SUBTITLE: 'Cobalah memuat ulang laman.',
          ON_REFRESH: 'Segarkan',
        },
        LOADING: 'Memuat data',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Ikhtisar',
        CANCELLED_VISITS: '% Kunjungan dibatalkan',
        BDRS_WITHOUT_VISITS: '% BDR tanpa kunjungan',
        TOTAL_OF_VISITS: 'Total kunjungan',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' dari [total] Kunjungan',
        BDRS_WITHOUT_VISITS_TOTAL: 'dari [total] BDR',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Terakhir diperbarui pada [lastUpdate] jam yang lalu.',
        FILTER_DESCRIPTION: 'Pilih rentang tanggal untuk menampilkan data:',
      },
      Button: {
        REFRESH: 'Segarkan',
        TRY_AGAIN: 'Coba lagi',
      },
      Error: {
        REFRESH_ERROR: 'Tidak dapat memuat ulang data',
        DEFAULT_ERROR: 'Terjadi kesalahan.',
        RELOAD_BUTTON: 'Muat ulang',
      },
      Filter: {
        Periods: {
          TODAY: 'Hari ini',
          YESTERDAY: 'Kemarin',
          TOMORROW: 'Besok',
          OTHER: 'Lainnya',
        },
      },
      Graph: {
        TITLE: 'Menurut status',
        FILTER_DESCRIPTION: 'Pilih rentang data yang ingin Anda tampilkan.',
        SCHEDULED: 'Direncanakan',
        IN_PROGRESS: 'Dalam proses',
        COMPLETED: 'Selesai',
        JUSTIFIED: 'Dibenarkan',
        CANCELED: 'Dibatalkan',
      },
      SearchField: {
        PLACEHOLDER: 'Cari',
      },
      LOADING: 'Memuat konten',
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'ID POC',
        BDR_ID: 'ID BDR',
        BDR_NAME: 'Nama BDR',
        SEARCH_BY: 'Cari menurut',
      },
      Buttons: {
        SEARCH_BUTTON: 'Cari',
        FILTERS_BUTTON: 'Filter',
        APPLY_BUTTON: 'Terapkan',
        CANCEL_BUTTON: 'Batalkan',
        CLEAR_ALL_BUTTON: 'Hapus semua',
        RELOAD_BUTTON: 'Muat ulang',
      },
      Title: {
        CARD_VISITS: 'Kunjungan',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'ID Akun [id] tidak ada.',
        BDR_ID_ERROR: 'BDR [id] tidak ada.',
        BDR_NAME_ERROR: 'BDR [name] tidak ada.',
      },
      StatusVisits: {
        STATUS: 'Status',
        SCHEDULED: 'Direncanakan',
        IN_PROGRESS: 'Dalam proses',
        COMPLETED: 'Selesai',
        JUSTIFIED: 'Dibenarkan',
        CANCELED: 'Dibatalkan',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Menampilkan [results] kunjungan',
        BDR_ID: 'ID BDR',
        BDR_NAME: 'Nama BDR',
        POC_ID: 'ID POC',
        VISIT_DATE: 'Tanggal Kunjungan',
        MIN_TIME: 'Waktu Min.',
        MAX_TIME: 'Waktu Maks.',
        MINUTES: 'menit',
        QUANTITY_INDICATION: 'dari',
        LOADING_CONTENT: 'Memuat data',
        ERROR_CONTENT: 'Terjadi kesalahan.',
        NO_CONTENT: 'Tidak ada konten untuk ditampilkan.',
        SEARCH_NOT_FOUND: 'Tidak ada hasil. Tinjau pencarian Anda dan coba lagi.',
        CLEAR_SEARCH: 'Hapus pencarian',
        POC_LATITUDE: 'Lintang',
        POC_LONGITUDE: 'Bujur',
      },
      Filter: {
        FILTERED_BY: 'Difilter',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDR tanpa kunjungan',
      },
      Label: {
        SHOWING_RESULTS: 'Menampilkan [results] hasil',
        BDR_ID: 'ID BDR',
        BDR_NAME: 'Nama BDR',
        DATE_WITHOUT_VISITS: 'Tanggal tanpa kunjungan',
        SEARCH_BY: 'Cari menurut ID BDR',
        PAGINATION_INDICATION: 'dari',
      },
    },
    ModalChangeDDC: {
      Title: {
        CHANGE_DDC: 'Ubah DDC',
      },
      Paragraph: {
        FIND_ANOTHER_DDC: 'Temukan DDC lain untuk dikelola menurut ID DDC atau nama DDC.',
      },
      Button: {
        CANCEL: 'Batalkan',
        CHANGE: 'Ubah',
        Label: {
          DDC: 'DDC',
        },
      },
      Select: {
        Search: {
          PLACEHOLDER: 'Cari',
        },
        LABEL: 'DDC',
        NOT_FOUND: 'Kami tidak menemukan apa yang Anda cari.',
        SHOW_ALL: 'Tampilkan semua',
      },
    },
    ModalChangeOperation: {
      Button: {
        Label: {
          OPERATION: 'Operasi',
          TOOLTIP_INFORMATION: 'Operasi tersebut mencakup organisasi dan penjual.',
        },
      },
      TITLE: 'Edit operasi',
      SUB_TITLE: 'Operasi akan diterapkan ke halaman kunjungan.',
      Actions: {
        CANCEL_BUTTON: 'Batalkan',
        SAVE_BUTTON: 'Simpan',
      },
      Fields: {
        Country: {
          LABEL: 'Negara',
          PLACEHOLDER: 'Pilih Negara',
        },
        Organization: {
          LABEL: 'Organisasi',
          HINT: 'Mungkin termasuk perusahaan.',
          PLACEHOLDER: 'Pilih organisasi',
          ALERT: 'Negara ini tidak memiliki organisasi',
        },
        Seller: {
          LABEL: 'Penjual',
          HINT: 'Siapa yang akan menerima kunjungan.',
          PLACEHOLDER: 'Pilih penjual',
        },
        VendorId: {
          LABEL: 'ID Vendor',
          PLACEHOLDER: 'Pilih ID Vendor',
        },
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detail kunjungan',
        POC_INFORMATION: 'Informasi POC',
        NEXT_VISIT: 'Kunjungan berikutnya',
        BDR_INFORMATION: 'Informasi BDR',
        VISIT_INFORMATION: 'Informasi kunjungan',
        CANCEL_VISIT: 'Batalkan kunjungan?',
      },
      Label: {
        VISIT_DATE: 'Tanggal kunjungan',
        VISIT_STATUS: 'Status kunjungan',
        SUGGESTED_TIME: 'Waktu yang disarankan',
        MINIMUM_TIME: 'Waktu minimum',
        MAXIMUM_TIME: 'Waktu maksimum',
        NO_NEXT_VISIT: 'Tidak ada kunjungan yang dijadwalkan untuk POC ini.',

        POC_NAME: 'Nama POC',
        POC_ID: 'ID POC',
        POC_LATITUDE: 'Lintang',
        POC_LONGITUDE: 'Bujur',
        NO_POC_INFORMATION: 'There is no POC information for this visit.',

        BDR_NAME: 'Nama BDR',
        BDR_ID: 'ID BDR',
        NO_BDR_INFORMATION: 'Tidak ada informasi BDR untuk POC ini.',

        SHOW_VISIT_DETAILS: 'Tampilkan detail kunjungan',
        MINUTES: '[min] menit',
        NO_DATA_AVAILABLE: 'Data tidak tersedia',
        CANCELED_DUE_TO: 'Kunjungan ini dibatalkan oleh sistem karena [error].',

        CANCEL_VISIT_DESCRIPTION: 'Tidak ada informasi POC untuk kunjungan ini.',

        LOADING_CONTENT: 'Memuat data',
        ERROR_CONTENT: 'Terjadi kesalahan.',
      },
      Button: {
        CANCEL_MODAL: 'Batalkan',
        GO_BACK: 'Tidak, kembali',
        CANCEL_VISIT: 'Ya, batalkan',
        REFRESH: 'Muat ulang',
      },
      Toast: {
        SUCCESS: 'Kunjungan dibatalkan',
      },
      PageStatus: {
        Error: {
          ERROR_MESSAGE: 'Tidak dapat memuat data',
          SUBTITLE: 'Cobalah memuat ulang laman.',
          ON_REFRESH: 'Muat ulang',
          ON_GO_TO: 'Kembali ke Kunjungan',
        },
        LOADING: 'Memuat data',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Simpan',
        RESET: 'Atur ulang',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Direncanakan',
        IN_PROGRESS: 'Dalam proses',
        COMPLETED: 'Selesai',
        JUSTIFIED: 'Dibenarkan',
        CANCELED: 'Dibatalkan',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Edit kunjungan',
        VISIT_DATE: 'Tanggal kunjungan',
        VISIT_STATUS: 'Status kunjungan',
        NEXT_VISIT: 'Kunjungan berikutnya',
        SCHEDULED: 'Direncanakan',
        CANCELED: 'Dibatalkan',
        NO_DATA_AVAILABLE: 'Data tidak tersedia',
        SAVED_CHANGE: 'Perubahan disimpan',
      },
      Button: {
        CANCEL: 'Batalkan',
        SAVE_CHANGES: 'Simpan perubahan',
        EDIT: 'Edit',
        SAVE: 'Simpan',
        RESET: 'Atur ulang',
      },
      Errors: {
        MAX_TIME_LESS_THAN_MIN_TIME:
          'Waktu maksimum tidak dapat kurang dari atau sama dengan waktu minimum.',
        MIN_TIME_LESS_THAN_2: 'Waktu minimum tidak dapat kurang dari 2 menit.',
      },
    },
  },
};
