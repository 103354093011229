import MessageMap from '../i18n.d';

export const es: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Solo disponible la opción en inglés',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
      Status: {
        Error: {
          ERROR_MESSAGE: 'No se pueden cargar los datos',
          SUBTITLE: 'Intenta volver a cargar la página.',
          ON_REFRESH: 'Actualizar',
        },
        LOADING: 'Cargando datos',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Descripción general',
        CANCELLED_VISITS: '% de visitas canceladas',
        BDRS_WITHOUT_VISITS: '% de RN sin visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] RNs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Ultima actualización [lastUpdate].',
        FILTER_DESCRIPTION: 'Selecciona un rango de fechas para mostrar los datos:',
      },
      Button: {
        REFRESH: 'Actualizar',
        TRY_AGAIN: 'Intentar de nuevo',
      },
      Error: {
        REFRESH_ERROR: 'No se pueden actualizar los datos',
        DEFAULT_ERROR: 'Algo salió mal.',
        RELOAD_BUTTON: 'Recargar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoy',
          YESTERDAY: 'Ayer',
          TOMORROW: 'Mañana',
          OTHER: 'Otro',
        },
      },
      Graph: {
        TITLE: 'Por estado',
        SCHEDULED: 'Planificadas',
        IN_PROGRESS: 'En curso',
        COMPLETED: 'Terminadas',
        JUSTIFIED: 'Justificadas',
        CANCELED: 'Canceladas',
      },
      SearchField: {
        PLACEHOLDER: 'Buscar',
      },
      LOADING: 'Cargando contenido',
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'Codigo de PDV',
        BDR_ID: 'Codigo de RN',
        BDR_NAME: 'Nombre de RN',
        SEARCH_BY: 'Buscar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Buscar',
        FILTERS_BUTTON: 'Filtrar',
        APPLY_BUTTON: 'Aplicar',
        CANCEL_BUTTON: 'Cancelar',
        CLEAR_ALL_BUTTON: 'Borrar todo',
        RELOAD_BUTTON: 'Recargar',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'Codigo de [id] no existe.',
        BDR_ID_ERROR: 'RN [id] no existe.',
        BDR_NAME_ERROR: 'RN [name] no existe.',
      },
      StatusVisits: {
        STATUS: 'Estado',
        SCHEDULED: 'Planificada',
        IN_PROGRESS: 'En curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Se muestran [results] visitas',
        BDR_ID: 'Codigo de RN',
        BDR_NAME: 'Nombre de RN',
        POC_ID: 'Código de PDV',
        VISIT_DATE: 'Fecha de la visita',
        MIN_TIME: 'Tiempo Min.',
        MAX_TIME: 'Tiempo Máx.',
        MINUTES: 'Minutos',
        QUANTITY_INDICATION: 'de',
        LOADING_CONTENT: 'Cargando datos',
        ERROR_CONTENT: 'Algo salió mal.',
        NO_CONTENT: 'No hay contenido para mostrar.',
        SEARCH_NOT_FOUND: 'Sin resultados. Revisa tu búsqueda e inténtalo de nuevo.',
        CLEAR_SEARCH: 'Borrar búsqueda',
      },
      Filter: {
        FILTERED_BY: 'Filtrado por',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'RN sin visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Se muestran [results] resultados',
        BDR_ID: 'Codigo de RN',
        BDR_NAME: 'Nombre de RN',
        DATE_WITHOUT_VISITS: 'Fecha sin visita',
        SEARCH_BY: 'Buscar por Codigo de RN',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'al',
      },
    },
    ModalChangeDDC: {
      Title: {
        CHANGE_DDC: 'Cambiar DDC',
      },
      Paragraph: {
        FIND_ANOTHER_DDC: 'Buscar otro DDC para administrar por ID o nombre de DDC.',
      },
      Button: {
        CANCEL: 'Cancelar',
        CHANGE: 'Cambiar',
        Label: {
          DDC: 'DDC',
        },
      },
      Select: {
        Search: {
          PLACEHOLDER: 'Buscar',
        },
        LABEL: 'DDC',
        NOT_FOUND: 'No encontramos lo que estás buscando.',
        SHOW_ALL: 'Mostrar todo',
      },
    },
    ModalChangeOperation: {
      Button: {
        Label: {
          OPERATION: 'Operación',
          TOOLTIP_INFORMATION: 'La operación incluye a la organización y al vendedor.',
        },
      },
      TITLE: 'Operación de edición',
      SUB_TITLE: 'La operación se aplicará a la página de visitas.',
      Actions: {
        CANCEL_BUTTON: 'Cancelar',
        SAVE_BUTTON: 'Guardar',
      },
      Fields: {
        Country: {
          LABEL: 'País',
          PLACEHOLDER: 'Selecione un País',
        },
        Organization: {
          LABEL: 'Organización',
          HINT: 'Puede incluir empresas.',
          PLACEHOLDER: 'Seleccione una organización',
          ALERT: 'Este país no tiene organizaciones',
        },
        Seller: {
          LABEL: 'Vendedor',
          HINT: 'Quién recibirá la visita.',
          PLACEHOLDER: 'Selecione un vendedor',
        },
        VendorId: {
          LABEL: 'ID del vendedor',
          PLACEHOLDER: 'Selecione un ID del vendedor',
        },
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalles de la visita',
        POC_INFORMATION: 'Información del PDV',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Información del RN',
        VISIT_INFORMATION: 'Información de la visita',
        CANCEL_VISIT: '¿Cancelar visita?',
      },
      Label: {
        VISIT_DATE: 'Fecha de la visita',
        VISIT_STATUS: 'Estado de la visita',
        SUGGESTED_TIME: 'Tiempo sugerido',
        MINIMUM_TIME: 'Tiempo mínimo',
        MAXIMUM_TIME: 'Tiempo máximo',
        NO_NEXT_VISIT: 'No hay visitas agendadas para este PDV.',

        POC_NAME: 'Nombre del PDV',
        POC_ID: 'Código de PDV',
        POC_LATITUDE: 'Latitud',
        POC_LONGITUDE: 'Longitud',
        NO_POC_INFORMATION: 'No hay información de POC para esta visita.',

        BDR_NAME: 'Nombre del RN',
        BDR_ID: 'Código del RN',
        NO_BDR_INFORMATION: 'No hay información de BDR para este POC.',

        SHOW_VISIT_DETAILS: 'Mostrar detalles de visita',
        MINUTES: '[min] minutos',
        NO_DATA_AVAILABLE: 'No hay datos disponibles',

        CANCEL_VISIT_DESCRIPTION:
          'Esta acción se puede deshacer siempre que la fecha permanezca en el futuro.',

        LOADING_CONTENT: 'Cargando datos',
        ERROR_CONTENT: 'Algo salió mal.',

        CANCELED_BY_USER_ADMIN: 'Esta visita fue cancelada por un usuario de BEES One.',
        CANCELED_BY_USER_SYNC: 'Esta visita fue cancelada por un usuario en Sync.',
        CANCELED_BY_SYSTEM: 'Esta visita fue cancelada por el sistema debido a [error].',
        CanceledBySystem: {
          INVALID_LAT_LONG: 'latitud o longitud inválida',
          NOT_IN_CUSTOMER: 'cuenta no disponible para el Vendor',
          NOT_IN_ACCOUNTS_BFF: 'cuenta no disponible para Force',
          INVALID_NAME: 'nombre inválido',
          NOT_IN_UCC: 'datos inválidos en UCC',
          INVALID_DELIVERY_ADDRESS: 'dirección de entrega inválida',
          BEES_ACCOUNT_ID_NULL: 'ID de cuenta nulo',
        },
      },
      Button: {
        CANCEL_MODAL: 'Cancelar',
        GO_BACK: 'No, volver',
        CANCEL_VISIT: 'Sí, cancelar',
        REFRESH: 'Recargar',
      },
      Toast: {
        SUCCESS: 'La visita se canceló correctamente.',
      },
      PageStatus: {
        Error: {
          ERROR_MESSAGE: 'No se pueden cargar los datos',
          SUBTITLE: 'Intenta volver a cargar la página.',
          ON_REFRESH: 'Recargar',
          ON_GO_TO: 'Volver a Visitas',
        },
        LOADING: 'Cargando datos',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Ahorrar',
        RESET: 'Reiniciar',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Planificada',
        IN_PROGRESS: 'En curso',
        COMPLETED: 'Terminada',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Editar visita',
        VISIT_DATE: 'Fecha de la visita',
        VISIT_STATUS: 'Estado de la visita',
        NEXT_VISIT: 'Próxima visita',
        SCHEDULED: 'Planificada',
        CANCELED: 'Cancelado',
        NO_DATA_AVAILABLE: 'No hay datos disponibles',
        SAVED_CHANGE: 'Cambios guardados',
      },
      Button: {
        CANCEL: 'Cancelar',
        SAVE_CHANGES: 'Guardar cambios',
        EDIT: 'Editar',
        SAVE: 'Ahorrar',
        RESET: 'Reiniciar',
      },
      Input: {
        Label: {
          SUGGESTED_TIME: 'Tiempo sugerido',
          MINIMUM_TIME: 'Tiempo mínimo',
          MAXIMUM_TIME: 'Tiempo máximo',
          PLACEHOLDER: 'Ingrese la hora',
        },
      },
      Errors: {
        MAX_TIME_LESS_THAN_MIN_TIME:
          'El valor del tiempo máximo no puede ser inferior o igual al valor del tiempo mínimo.',
        MIN_TIME_LESS_THAN_2: 'El tiempo mínimo no puede ser menor que 2 minutos.',
      },
    },
  },
};
