import { useIntl } from 'react-intl';

interface UseTranslationProps {
  editVisit: string;
  visitDate: string;
  visitStatus: string;
  nextVisit: string;
  cancel: string;
  saveChanges: string;
  scheduled: string;
  canceled: string;
  edit: string;
  reset: string;
  save: string;
  noDataAvailable: string;
  savedChanges: string;
  errorOnSave: string;
  errorOnSaveMinTime: string;
}

enum TranslationKeys {
  Button = 'Button',
  LABEL = 'Label',
  ERRORS = 'Errors',
}

export const useTranslation = (): UseTranslationProps => {
  const { formatMessage } = useIntl();

  const getTranslationById = (key: string, value: string): string => {
    return formatMessage({ id: `Components.EditModal.${key}.${value}` });
  };

  return {
    editVisit: getTranslationById(TranslationKeys.LABEL, 'EDIT_VISIT'),
    visitDate: getTranslationById(TranslationKeys.LABEL, 'VISIT_DATE'),
    visitStatus: getTranslationById(TranslationKeys.LABEL, 'VISIT_STATUS'),
    nextVisit: getTranslationById(TranslationKeys.LABEL, 'NEXT_VISIT'),
    scheduled: getTranslationById(TranslationKeys.LABEL, 'SCHEDULED'),
    canceled: getTranslationById(TranslationKeys.LABEL, 'CANCELED'),
    noDataAvailable: getTranslationById(TranslationKeys.LABEL, 'NO_DATA_AVAILABLE'),
    savedChanges: getTranslationById(TranslationKeys.LABEL, 'SAVED_CHANGE'),
    cancel: getTranslationById(TranslationKeys.Button, 'CANCEL'),
    saveChanges: getTranslationById(TranslationKeys.Button, 'SAVE_CHANGES'),
    edit: getTranslationById(TranslationKeys.Button, 'EDIT'),
    reset: getTranslationById(TranslationKeys.Button, 'RESET'),
    save: getTranslationById(TranslationKeys.Button, 'SAVE'),
    errorOnSave: getTranslationById(TranslationKeys.ERRORS, 'MAX_TIME_LESS_THAN_MIN_TIME'),
    errorOnSaveMinTime: getTranslationById(TranslationKeys.ERRORS, 'MIN_TIME_LESS_THAN_2'),
  };
};
