import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeatureToggleEnabledByVendorId } from '@bees-force-shared/services';
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';

import { VisitStatus } from '@/api/services/visits/types';
import { FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE } from '@/consts/FeatureToggles';
import { PATH_BASE, PATH_HOME } from '@/consts/route';
import { Locales } from '@/enums/locales';
import { vendorIds } from '@/mocks/vendorId';
import { useQueryVisitDetails } from '@/reactQueryHooks/visits/useQueryVisitDetails';
import {
  ComponentsName,
  VisitCancelSegmentProps,
  VisitDetailsSegmentProps,
} from '@/types/AnalyticsTypes';
import { GetVisitDetailsRequestQuery, VisitDetails } from '@/types/domains/GetVisitDetailsDomain';

import { useSegmentVisitCancel } from '../components/CancelVisitModal/hooks/useSegmentVisitCancel';
import { useSegmentVisitDetails } from './useSegmentVisitDetails/useSegmentVisitDetails';
import { useTranslation } from './useTranslation/useTranslation';

interface VisitDetailsProps {
  visitDetails?: VisitDetails;
}

export const useVisitDetails = ({ visitDetails }: VisitDetailsProps) => {
  const navigate = useNavigate();
  const { dispatchVisitDetailsEvent } = useSegmentVisitDetails();
  const toastService = useToast();
  const { cancelVisitToastSuccess } = useTranslation();

  /* TODO: Get the vendorId from localstorage after the operations US is done */
  const portalConfig = localStorage.getItem('portal-config');
  /* istanbul ignore next */
  const env = portalConfig ? JSON.parse(portalConfig).ENV : null;
  const {
    actions: { deleteVisit },
  } = useQueryVisitDetails({ vendorId: vendorIds[env] } as GetVisitDetailsRequestQuery);

  const { dispatchVisitCancelEvent } = useSegmentVisitCancel();

  const { isEnabled: isFeatureVisitEffectiveRouteEnabled } = useFeatureToggleEnabledByVendorId(
    FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE,
  );

  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2() as {
    preferredLanguage: Locales;
    defaultLanguage: Locales;
  };
  const locale = (preferredLanguage || defaultLanguage) as Locales;

  const canceledByUser = useMemo(() => ['canceled_by_user_admin', 'canceled_by_user_sync'], []);

  const wasCanceledByUser = useMemo(() => {
    const cancelationReason =
      visitDetails?.currentVisit.cancelationReason &&
      visitDetails.currentVisit.cancelationReason[0];

    return cancelationReason && canceledByUser.includes(cancelationReason);
  }, [visitDetails, canceledByUser]);

  const wasCanceledBySystem = useMemo(() => {
    const cancelationReason =
      visitDetails?.currentVisit.cancelationReason &&
      visitDetails.currentVisit.cancelationReason[0];

    return cancelationReason && !canceledByUser.includes(cancelationReason);
  }, [visitDetails, canceledByUser]);

  const handleClickGoBackToVisitListButton = () => {
    navigate(`${PATH_HOME}${PATH_BASE}`);
    dispatchVisitDetailsEvent(
      VisitDetailsSegmentProps.BackVisitList,
      VisitDetailsSegmentProps.VisitDetails,
    );
  };

  const handleClickBackButton = (visitId: string | null) => {
    dispatchVisitCancelEvent({
      buttonName: VisitCancelSegmentProps.Back,
      componentName: ComponentsName.cancelVisit,
      visitID: visitId,
    });
  };

  const handleCancelVisit = async (visitId: string) => {
    dispatchVisitCancelEvent({
      buttonName: VisitCancelSegmentProps.Cancel,
      componentName: ComponentsName.cancelVisit,
      visitID: visitId,
    });

    await deleteVisit.mutate(visitId);

    toastService.notify({
      message: cancelVisitToastSuccess,
      type: TypeToast.SUCCESS,
    });
  };

  const handleGoToVisitListErrorButton = () => {
    navigate(`${PATH_HOME}${PATH_BASE}`);
  };

  const visitDetailsInfo = {
    accountId: visitDetails?.currentVisit.accountId ?? '',
    currentVisitDate: visitDetails?.currentVisit.date ?? '',
    currentVisitStatus: visitDetails?.currentVisit.status ?? '',
    currentVisitEstimatedTimeMin: visitDetails?.currentVisit.estimatedTimeMin ?? '',
    currentVisitEstimatedTimeMax: visitDetails?.currentVisit.estimatedTimeMax ?? '',
    currentVisitSuggestedTime: visitDetails?.currentVisit.suggestedTime ?? '',
    bdrId: visitDetails?.bdrId ?? '',
    nextVisitDate: visitDetails?.nextVisit?.date ?? '',
    nextVisitStatus: visitDetails?.nextVisit?.status ?? '',
    cancelationReason: visitDetails?.currentVisit.cancelationReason ?? null,
    nextVisitId: visitDetails?.nextVisit?.id ?? '',
  };

  const isScheduled = visitDetailsInfo.currentVisitStatus === VisitStatus.SCHEDULE;
  const isCanceled = visitDetailsInfo.currentVisitStatus === VisitStatus.CANCELED;
  const isVisitDatePassed = visitDetailsInfo.currentVisitDate
    ? new Date(visitDetailsInfo.currentVisitDate) <= new Date()
    : false;

  const isCanceledActionAvailable = isScheduled && !isVisitDatePassed;
  const isVisitEditVisible = (isScheduled || isCanceled) && !isVisitDatePassed;

  return {
    isFeatureVisitEffectiveRouteEnabled,
    handleClickGoBackToVisitListButton,
    locale,
    handleCancelVisit,
    handleClickBackButton,
    handleGoToVisitListErrorButton,
    visitDetailsInfo,
    isCanceledActionAvailable,
    isVisitEditVisible,
    wasCanceledByUser,
    wasCanceledBySystem,
  };
};
