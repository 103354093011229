import { useNavigate, useSearchParams } from 'react-router-dom';

import { EmptyContent, SearchNotFound } from '@bees-force-shared/components';
import { Table } from '@hexa-ui/components';
import { useUnit } from 'effector-react';

import { Order, VisitsModel } from '@/api/services/visits/types';
import { vendorIds } from '@/mocks/vendorId';
import { useQueryVisitList } from '@/reactQueryHooks/visits/useQueryVisitList';
import { FiltersKpisParametersStore } from '@/stores/filtersKpisStore/FiltersKpisParametersStore';
import { useVisitsParamStore } from '@/stores/visitsParams/VisitsParamsStore';

import { useTableVisits } from '../../hooks/useTableVisits/useTableVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { ErrorContent } from '../ErrorContent/ErrorContent';
import { LoadingContent } from '../LoadingContent/LoadingContent';
import * as Styled from './TableVisits.styles';

export const TableVisits = () => {
  const { startDate, endDate } = useUnit(FiltersKpisParametersStore);
  const { status, bdrId, accountId } = useVisitsParamStore();
  const { noContent, searchNotFound, clearSearch } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  /* TODO: Get the vendorId from localstorage after the operations US is done */
  const portalConfig = localStorage.getItem('portal-config');
  /* istanbul ignore next */
  const env = portalConfig ? JSON.parse(portalConfig).ENV : null;

  const { columns, handleClickNavigate, setSortDetails, handleClearSearch } = useTableVisits();

  const { visitsResponse, isErrorVisits, isFetchingVisits } = useQueryVisitList({
    vendorId: vendorIds[env],
    startDate,
    endDate,
    status,
    bdrId: bdrId || undefined,
    accountId: accountId || undefined,
    page: Number(searchParams.get('page')) || 1,
    sort: searchParams.get('sort') as Order,
  });

  const hasVisits = visitsResponse?.data?.content?.length;

  const hasSearchByBdrOrAccount = bdrId || accountId;

  return (
    <Styled.TableWrapper>
      {isFetchingVisits && <LoadingContent />}
      {isErrorVisits && <ErrorContent />}
      {!hasVisits && hasSearchByBdrOrAccount && !isFetchingVisits && (
        <SearchNotFound
          notFoundMessage={searchNotFound}
          buttonMessage={clearSearch}
          buttonAction={handleClearSearch}
        />
      )}
      {!hasVisits && !isFetchingVisits && <EmptyContent noContentText={noContent} />}
      <Styled.TableContainer hidden={!hasVisits || isFetchingVisits || !!isErrorVisits}>
        <Table
          columns={columns}
          data={visitsResponse?.data.content || []}
          pagination={{
            pageSize: Number(visitsResponse?.data.pagination.pageSize),
            total: Number(visitsResponse?.data.pagination.totalElements),
            current: Number(searchParams.get('page')) || 1,
            onChange: (page) => {
              const params = new URLSearchParams(searchParams);
              params.set('page', page.toString());
              navigate(`?${params.toString()}`);
            },
          }}
          onRow={(rowData: VisitsModel) => ({
            'data-testid': `visit-row-${rowData.id}`,
            onClick: () => handleClickNavigate(rowData),
          })}
          onSort={(column, direction) => {
            setSortDetails({ column, direction });
            const params = new URLSearchParams(searchParams);
            if (direction && column) params.set('sort', `${column},${direction.toLowerCase()}`);
            if (!direction && !column) params.delete('sort');
            navigate(`?${params.toString()}`);
          }}
        />
      </Styled.TableContainer>
    </Styled.TableWrapper>
  );
};
